import request from '@/utils/request'

const appletApi = {
  wechat_corporation_config_list: '/wechat/corporation_config/',
  wechat_corporation_config_create: '/wechat/corporation_config/',
  wechat_corporation_config_update: '/wechat/corporation_config/',
  wechat_corporation_config_delete: '/wechat/corporation_config/'
}

export function wechat_corporation_config_list (query) {
  return request({
    url: appletApi.wechat_corporation_config_list,
    method: 'get',
    params: query
  })
}
export function wechat_corporation_config_create (parameter) {
  return request({
    url: appletApi.wechat_corporation_config_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function wechat_corporation_config_update (data, id) {
  return request({
    url: appletApi.wechat_corporation_config_update + id + '/',
    method: 'put',
    data
  })
}

/**
 * 删除
 */
export function wechat_corporation_config_delete (id) {
  return request({
    url: appletApi.wechat_corporation_config_delete + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
