<template>
  <div style="width:80%; margin:0 auto;">
    <a-spin :spinning="spinning">
      <div class="spin-content">
        <a-tabs style="background-color: #ffffff;padding: 20px; height: 100vh">
          <a-tab-pane :key="1" tab="商城配置">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="8" :sm="24">
                  <a-form-item label="商城订单审核开启">
                    <a-switch @change="handleChange" :checked="defaultChecked" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  wechat_corporation_config_create,
  wechat_corporation_config_list,
  wechat_corporation_config_update
} from '@/api/wx_corporation_config'

export default {
  name: 'CorporationConfigSettings',
  data () {
    return {
      spinning: false,
      delayTime: 2000,
      defaultChecked: false,
      create_update: 0,
      id: 0
    }
  },
  mounted () {
    wechat_corporation_config_list().then(res => {
      this.create_update = res.data.entries.length
      if (this.create_update > 0) {
        this.defaultChecked = res.data.entries[0].audit
        this.id = res.data.entries[0].id
      }
    })
  },
  methods: {
    handleChange (checked) {
      this.spinning = true
      console.log(checked)
      const params = {
        corporation_id: this.$Dictionaries.corporation_id,
        audit: checked
      }
      if (this.create_update === 0) {
        wechat_corporation_config_create(params).then(res => {
          if (res.data) {
            this.defaultChecked = res.data.audit
          }
        }).finally(() => {
          this.spinning = false
        })
      } else {
        wechat_corporation_config_update(params, this.id).then(res => {
          if (res.data) {
            this.defaultChecked = res.data.audit
          }
        }).finally(() => {
          this.spinning = false
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>

.avatar-upload-wrapper {
  height: 200px;
  width: 100%;
}

.ant-upload-preview {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img, .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
